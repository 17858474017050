<template>
  <div class="courses">
    <div class="hero is-info">
      <div class="hero-body has-text-centered">
        <h1 class="title">Roles</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2">
            <aside class="menu">
              <p class="menu-label">Categories</p>

              <ul class="menu-list">
                <li>
                  <a
                    v-bind:class="{ 'is-active': !activeCategory }"
                    @click="setActiveCategory(null)"
                  >
                    All courses
                  </a>
                </li>
                <li
                  v-for="category in categories"
                  v-bind:key="category.id"
                  @click="setActiveCategory(category)"
                >
                  <a>{{ category.title }}</a>
                </li>
              </ul>
            </aside>
          </div>

          <div class="column is-10">
            <div class="columns is-multiline">
              <template v-if="$store.state.user.isAuthenticated">
                <div
                  class="column is-4"
                  v-for="course in courses"
                  v-bind:key="course.id"
                >
                  <CourseItem :course="course" />
                </div>
              </template>
              <template v-else>
                <div class="body" style="text-align: center">
                  <h3>
                    Please Login to view the courses
                  </h3>
                </div>
              </template>

              <div class="column is-12">
                <nav class="pagination">
                  <a class="pagination-previous">Previous</a>
                  <a class="pagination-next">Next</a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

import CourseItem from "../components/CourseItem.vue";

export default {
  data() {
    return {
      courses: [],
      categories: [],
      activeCategory: null,
    };
  },
  components: {
    CourseItem,
  },
  async mounted() {
    console.log("mounted");

    document.title = "Roles | SkillProof";

    await axios.get("/jobs").then((response) => {
      console.log(response.data);

      this.courses = response.data;
    });
  },
  methods: {
    setActiveCategory(category) {
      console.log(category);
      this.activeCategory = category;

      this.getCourses();
    },
    getCourses() {
      let url = "courses/";

      if (this.activeCategory) {
        url += "?category_id=" + this.activeCategory.id;
      }

      axios.get(url).then((response) => {
        console.log(response.data);

        this.courses = response.data;
      });
    },
  },
};
</script>
