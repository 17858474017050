<template>
  <div class="about">
    <div class="hero is-info">
      <div class="hero-body has-text-centered">
        <h1 class="title">About</h1>
      </div>
    </div>

    <section class="section">
      THis is the about page
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = 'About | StudyNet'
  }
}
</script>