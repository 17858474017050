import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import axios from 'axios'

axios.defaults.baseURL = 'http://localhost:5000'

import gAuthPlugin from 'vue3-google-oauth2';
const app = createApp(App);
let gauthClientId = '884910657854-el50aljt697qb4kan9f13vjb7nfi5j0l.apps.googleusercontent.com';

app.use(gAuthPlugin, {
  clientId: gauthClientId,
  scope: 'email',
  scope: 'profile',
  prompt: 'consent',
})

app.use(store).use(router, axios).mount('#app')
