<template>
  <a href="/">
    <div
      class="box column is-3 mt-3 body1"
      v-for="item in jobs"
      v-bind:key="item.id"
    >
      <div class="cloumn ">
        <h1>{{ item.job_title }}</h1>
        <h2>{{ item.job_description }}</h2>
      </div>
    </div>
  </a>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      jobs: [],
    };
  },
  async mounted() {
    console.log("mounted");
    await axios.get("/jobs").then((response) => {
      console.log(response.data);
      this.jobs = response.data;
    });
  },
};
</script>
<style>
.body1 {
  display: flex;
  flex-direction: row;
}
</style>
