import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import SignUp from '../views/SignUp.vue'
import loginForm from '../views/LogIn.vue'

import Courses from '../views/Courses.vue'
import Course from '../views/Course.vue'
import signRecuiter from '../views/signRecuiter'
import MyAccount from '../views/dashboard/MyAccount.vue'
import logRecuiter from "../views/loginRecuiter.vue"
import jobForm from "../views/jobForm"
import recruiterDashboard from '../views/dashboard/RecruiterDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/login',
    name: 'LogIn',
    component: loginForm
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/course',
    name: 'Course',
    component: Course
  },
  // {
  //   path: '/courses/:slug',
  //   name: 'Course',
  //   component: Course
  // },

  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/dashboard/recruiter-account',
    name: 'MyAccount',
    component: recruiterDashboard
  },

  {
    path: '/signin-recuiter',
    name: 'SignInRecuiter',
    component: signRecuiter
  },
  {
    path: '/login-recuiter',
    name: 'LogInRecuiter',
    component: logRecuiter
  },
  {
    path: '/job-forms',
    name: 'JobForm',
    component: jobForm
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
