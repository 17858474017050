<template>
  <div class="login">
    <div class="hero is-info">
      <div class="hero-body has-text-centered" style="background-color:  white">
        <h1 class="title" style="color: #3f8ecf">Add Assignments</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <form v-on:submit.prevent="submitForm">
              <div class="field">
                <label>Job Title</label>
                <div class="control">
                  <input type="text" class="input" v-model="job_title" />
                </div>
              </div>

              <div class="field">
                <label>Job Description</label>
                <div class="control">
                  <!-- <input type="text" class="input" v-model="job_description" /> -->
                  <textarea class="input" v-model="job_description"></textarea>
                </div>
              </div>

              <!-- <div class="notification is-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </p>
              </div> -->

              <div class="field">
                <label>Assignment 1 link</label>
                <div class="control">
                  <input type="url" class="input" v-model="assignment_1" />
                </div>
              </div>

              <div class="field">
                <label>Assignment 2 link</label>
                <div class="control">
                  <input type="url" class="input" v-model="assignment_2" />
                </div>
              </div>

              <div class="field">
                <label>Assignment 3 link</label>
                <div class="control">
                  <input type="url" class="input" v-model="assignment_3" />
                </div>
              </div>

              <div class="field">
                <div class="control ">
                  <button class="button is-dark button1">Submit</button>
                  <hr />
                </div>
              </div>
            </form>

            <!-- <hr /> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      job_title: "",
      job_description: "",
      assignment_1: "",
      assignment_2: "",
      assignment_3: "",
    };
  },
  methods: {
    submitForm() {
      this.errors = [];

      if (this.job_title === "") {
        this.errors.push("The Job Title is missing!");
      }

      if (this.job_description === "") {
        this.errors.push("The Job Description is missing!");
      }

      if (this.assignment_1 === "") {
        this.errors.push("The Assignment 1 is missing!");
      }

      if (this.assignment_2 === "") {
        this.errors.push("The Assignment 2 is missing!");
      }

      if (this.assignment_3 === "") {
        this.errors.push("The Assignment 3 is missing!");
      }

      if (!this.errors.length) {
        const formData = {
          job_title: this.job_title,
          job_description: this.job_description,
          assignment_1: this.assignment_1,
          assignment_2: this.assignment_2,
          assignment_3: this.assignment_3,
        };

        axios
          .post("/jobs", formData, {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Methods": "POST",
              // "Access-Control-Allow-Headers": "Authorization"
              // "Access-Control-Allow-Credentials": "true"
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              this.$router.push("/courses");
            } else {
              //
              this.$toast.open({
                message: "Please fill all the fields",
                type: "error",
                position: "top-right",
              });
            }
          });
      }
    },
  },
};
</script>

<style>
.button1 {
  width: 100%;
}
textarea.input {
  min-height: 120px;
}
</style>
